<template>
    <div class="m-4">
        <div class="fl-te-c">
            <h4>Course Register Details</h4>
            <div class="btn-group">
                <btn :disabled="updatingPayment" :loading="updatingPayment" loading-text="Processing..."
                     v-if="!details.is_online_payment && details.payment_status!=='Transaction Completed'" size="sm"
                     title="Receive Payment" @click="updatePaymentStatus(details.id)"/>
                <btn text="Back" @click="$router.go(-1)" size="sm"/>
            </div>
        </div>
        <div v-if="details">
            <div class="card row ml-1 mr-1 mb-4 mt-2">
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Course Register ID</span><br/>
                    <div>{{ details.id }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Reference ID</span><br/>
                    <div>{{ details.reference_id }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Registered Course</span><br/>
                    <div>{{ details.course.name }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Registered By</span><br/>
                    <div>{{ details.user }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">User Type</span><br/>
                    <div>{{ details.user_type }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Registered On</span><br/>
                    <div>{{ details.created_date }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Total amount including applicants</span><br/>
                    <div>{{ details.total_include_applicants }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Payment Status</span><br/>
                    <div>{{ details.payment_status }}</div>
                </div>
                <div class="col-3 p-2 round-2">
                    <span class="font-weight-bold">Payment Method</span><br/>
                    <div v-if="details.is_online_payment">Online</div>
                    <div v-else>Offline</div>
                </div>
            </div>
            <h4 class="mb-4">Registered Applicants</h4>
            <simple-table class="card" :data="details.applicants" :fields="fields" title="Applicants"/>
        </div>
        <p v-else class="text-center p-3">No data available.</p>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'CourseRegisterDetailsPage',
    data () {
        return {
            updatingPayment: false,
            loading: false,
            details: '',
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'gender',
                    sortField: 'gender',
                    title: 'Gender'
                },
                {
                    name: 'dob',
                    sortField: 'dob',
                    title: 'DOB'
                },
                {
                    name: 'phone_number',
                    sortField: 'phone_number',
                    title: 'Phone'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'E-Mail'
                },
                {
                    name: 'adhar_number',
                    sortField: 'adhar_number',
                    title: 'Aadhar No'
                },
                {
                    name: 'marital_status',
                    sortField: 'marital_status',
                    title: 'Marital Status'
                }
            ],
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.admin.payment.courseRegisterDetails, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.loading = false;
        },
        async updatePaymentStatus (id) {
            this.updatingPayment = true;
            const response = await axios.form(urls.admin.payment.updatingPaymentStatus, {
                id: id,
                status: 'Transaction Completed',
                is_online_payment: false
            });
            const json = response.data;
            if (json.error === false) {
                this.$notify('Successfully Updated', 'Success', {
                    type: 'success'
                });
                const refs = this.$refs;
                refs.table.refreshTable();
            } else {
                if (json.msg) {
                    this.$notify(json.msg, 'Error', {
                        type: 'danger'
                    });
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.updatingPayment = false;
        }
    }
};
</script>

<style scoped>

</style>
